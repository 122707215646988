import React from "react"
import { useLocation } from "@reach/router"

interface Props {
  children: React.ReactNode
}

export const ScrollToTop = ({ children }: Props) => {
  const location = useLocation()

  React.useEffect(() => window.scrollTo(0, 0), [location.pathname])
  return <React.Fragment>{children}</React.Fragment>
}

import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import { Label } from ".."
import { IconButton } from "../.."

export const InputPassword = ({ label, name, autocomplete }) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false)
  const [field, meta] = useField(name)

  return (
    <div className={meta.touched && meta.error ? " form-error" : ""}>
      {label ? <Label field={name}>{label}</Label> : ""}
      <div
        className={`input-password${
          passwordVisible ? " password-visible" : ""
        }`}
      >
        <input
          className={`input${meta.touched && meta.error ? " input-error" : ""}`}
          type={passwordVisible ? "text" : "password"}
          name={name}
          id={name}
          autoComplete={autocomplete ? autocomplete : name}
          {...field}
        />
        <IconButton
          icon={passwordVisible ? "eye-off" : "eye"}
          aria-label={passwordVisible ? "Hide password" : "View password"}
          onClick={() =>
            setPasswordVisible((passwordVisible) => !passwordVisible)
          }
          type="button"
        />
      </div>
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

InputPassword.defaultProps = {
  label: "",
}

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  autocomplete: PropTypes.string,
}

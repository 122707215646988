import React from "react"

import { ButtonWithIcon } from "../../.."

interface Props {
  children: React.ReactNode
  backFunc?: () => void | undefined
}

export const ModalFooter = ({ children, backFunc = undefined }: Props) => {
  if (backFunc) {
    return (
      <footer className="modal-footer modal-footer-back-button">
        <ButtonWithIcon
          type="button"
          variant="secondary"
          iconPosition="left"
          icon="arrow-left"
          onClick={backFunc}
        >
          Back
        </ButtonWithIcon>
        <div className="modal-footer-back-button-contents">{children}</div>
      </footer>
    )
  } else {
    return <footer className="modal-footer">{children}</footer>
  }
}

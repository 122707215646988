import React from "react"
import PropTypes from "prop-types"

export const ProgressCircle = ({ radius, stroke, progress }) => {
  const [strokeDashoffset, setStrokeDashoffset] = React.useState(0)
  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI

  // Progress should be a decimal of 1 reflecting the % of progress
  React.useEffect(() => {
    setStrokeDashoffset(circumference - progress * circumference)
    return () => {
      setStrokeDashoffset(0)
    }
  }, [progress, circumference])

  return (
    <div className="progress-circle">
      <svg
        height={radius * 2}
        width={radius * 2}
        className="progress-circle-ring"
      >
        <circle
          stroke="#FFAE33"
          fill="transparent"
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeDasharray={circumference + " " + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  )
}

ProgressCircle.propTypes = {
  radius: PropTypes.number.isRequired,
  stroke: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
}

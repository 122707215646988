export const antigenResultOptions = [
  { value: "Negative", label: "Negative" },
  { value: "Positive", label: "Positive" },
  { value: "Invalid", label: "Invalid" },
]

export const PCRResultOptions = [
  { value: "Not detected", label: "Not detected" },
  { value: "Detected", label: "Detected" },
  { value: "Invalid", label: "Invalid" },
  { value: "Inconclusive", label: "Inconclusive" },
]

export const antibodyResultOptions = [
  { value: "IgG+", label: "IgG+" },
  { value: "IgG-", label: "IgG-" },
  { value: "IgM+", label: "IgM+" },
  { value: "IgM-", label: "IgM-" },
  { value: "IgG & IgM +", label: "IgG & IgM +" },
  { value: "IgG & IgM -", label: "IgG & IgM -" },
  { value: "Invalid", label: "Invalid" },
]

import React from "react"

import { Button } from "../.."

interface Props {
  children: React.ReactNode
  deleteButtonLoading: boolean
  deleteButtonText: string
  deleteFunc: () => void
  dismissFunc: () => void
  isVisible: boolean
  title: string
}

export const WarningModal = ({
  children,
  deleteButtonLoading,
  deleteButtonText,
  deleteFunc,
  dismissFunc,
  isVisible,
  title,
}: Props) => {
  React.useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }

    return () => {
      document.body.style.overflow = "visible"
    }
  }, [isVisible])

  if (isVisible) {
    return (
      <>
        <div className="modal warning-modal">
          <header className="warning-modal-header">
            <span className="icon-alert-circle-outline"></span>
            <h2 className="heading-small warning-modal-header-title">
              {title}
            </h2>
          </header>
          <div className="warning-modal-body">{children}</div>
          <footer className="warning-modal-footer">
            <Button
              onClick={deleteFunc}
              variant="delete"
              disabled={deleteButtonLoading}
              loading={deleteButtonLoading}
            >
              {deleteButtonText}
            </Button>
            <Button onClick={dismissFunc} variant="secondary">
              Cancel
            </Button>
          </footer>
        </div>
        <div
          className={`modal-overlay`}
          role="button"
          aria-label="Close pop-up"
          onClick={dismissFunc}
        />
      </>
    )
  } else {
    return null
  }
}

import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"
import { useQueryParam, StringParam } from "use-query-params"
import { navigate } from "@reach/router"

import { Button, Form, InputPassword } from "../shared"
import {
  handleAPIError,
  replaceSpaceWithPlusQueryParams,
} from "../../utilities"
import { SEOTitleTemplate } from "../../constants"

export const CreatePassword = () => {
  const { addToast } = useToasts()
  const [email] = useQueryParam("email", StringParam)
  const [token] = useQueryParam("token", StringParam)

  const onSubmit = async (
    values: { password: string },
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => {
    try {
      if (email) {
        const emailParsed = replaceSpaceWithPlusQueryParams(email)

        await axios({
          method: "post",
          url: "/user/reset-password",
          data: {
            email: emailParsed,
            password: values.password,
            token: token,
          },
        })
        addToast("Password updated, you can now log in", {
          appearance: "success",
        })
        navigate("/")
      }
    } catch (error) {
      addToast(handleAPIError(error, "create new password"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }
  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Create new password" })} />
      <Formik
        initialValues={{
          password: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required("Please enter a new password"),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form variant="card">
            <h1 className="heading-large form-card-heading-left">
              Create new password
            </h1>
            <p className="form-card-paragraph">
              You’ll be able to log in again straight away.
            </p>
            <InputPassword label="New password" name="password" />
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              formCard={true}
            >
              Update password
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
